<template>
  <v-card class="border-right" :height="pageHeight(270)">
    <div class="pa-3">
        <v-btn :ripple="false" class="text-center" color="white" block @click="synchronizeDrawers('patientEdit')">
          <v-icon left color="main_black">mdi-pencil</v-icon>
          <span class="color-black">{{ $t("edit-details") }}</span>
        </v-btn>
    </div>
    <div>
      <v-row>
        <v-col>
            <v-col class="mx-1">
              <span class="font-weight-bold color-black font-size22 float-left">{{ detailedUser.userData.firstName + ' ' + detailedUser.userData.lastName }}</span>
              <span class="float-right mr-2 mt-1" :style="{ fontWeight: detailedUser.userData.status === 1 ? 'bold' : 'bold', color: detailedUser.userData.status === 1 ? '#09CB09' : '' }">
              <v-icon small class="mr-1"  :style="detailedUser.userData.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
              {{ detailedUser.userData.status === 1 ? $t('active-caps') : $t('inactive-caps') }}
              </span>
            </v-col>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-left ml-4">
          <v-icon size="18" color="primary">mdi-email</v-icon>
          <span class="ml-2 color-primary font-size16"> {{ detailedUser.userData.email }}</span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-left ml-4 mt-n2">
          <v-icon size="18" color="primary" >mdi-phone</v-icon>
          <span class="ml-2 color-primary font-size16" >{{phone(detailedUser.userData.phone)}}</span>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0 mx-3 mt-2 mb-5">
        <v-col cols="4" class="data-table-outline border-radius-left5 d-flex flex-column-reverse pa-0">
          <span>{{ $t("age") }}</span>
          <span class="font-weight-bold">{{ calculateAge(detailedUser.userData.birthdate) }}</span>
        </v-col>
        <v-col cols="4" class="data-table-outline flex-column d-flex pa-0">
          <span class="font-weight-bold">{{userSettings.Units === "METRIC" ?
              detailedUser.userData.patientMetadata.weightInKg : toLbs
              (detailedUser.userData.patientMetadata.weightInKg)}}
              {{userSettings.Units === "METRIC" ? 'kg' : 'lbs'}}
           </span>
          <span>{{ $t("weight") }}</span>
        </v-col>
        <v-col cols="4" class="data-table-outline border-radius-right5 d-flex flex-column-reverse pa-0">
          <span>{{$t('height')}}</span>
          <span class="font-weight-bold">{{ userSettings.Units === 'IMPERIAL' ?
              formatHeightInFeet(detailedUser.userData.patientMetadata.heightInCm)
              : detailedUser.userData.patientMetadata.heightInCm + ' cm' }}
          </span>
        </v-col>
      </v-row>
    </div>
    <v-card class="overflow-y-auto overflow-x-hidden elevation-0 " :height="pageHeight(34)">
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="6" class="pa-0">
            <span class="float-left font-size-small-bold-text ml-4">{{ $t("knownDiagnosis") }}</span>
          </v-col>
          <v-col cols="6">
            <v-icon color="primary" size="18" @click="synchronizeDrawers('diagnosisEdit')" class="float-right">mdi-pencil</v-icon>
          </v-col>
        </v-card-title>
        <v-card-text v-html="getLatestDiagnosis" style="overflow-y: auto; max-height: 15vh;" class="pa-2"></v-card-text>
      </v-card>
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="6" class="pa-0">
            <span class="float-left font-size-small-bold-text ml-4">{{$t("treatment-summary")}}</span>
          </v-col>
          <v-col cols="6">
            <v-icon @click="synchronizeDrawers('treatmentEdit')" class="float-right" color="primary" size="18">mdi-pencil</v-icon>
          </v-col>
        </v-card-title>
        <v-card-text v-html="getTreatmentSummary" style="overflow-y: auto; max-height: 15vh;" class="py-0"></v-card-text>
      </v-card>
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="6" class="pa-0">
            <span class="float-left font-size-small-bold-text ml-4">{{ $t("allergies") }}</span>
          </v-col>
          <v-col cols="6">
            <v-icon @click="synchronizeDrawers('allergiesEdit')" class="float-right" color="primary" size="18">mdi-pencil</v-icon>
          </v-col>
        </v-card-title>
        <v-card-text v-html="getAllergies" style="overflow-y: auto; max-height: 15vh;" class="py-0"></v-card-text>
      </v-card>
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="6" class="pa-0">
            <span class="float-left font-size-small-bold-text ml-4">{{ $t("medicalTeam") }}</span>
          </v-col>
          <v-col cols="6" class="pa-0">
            <h6 @click="triggerMedicalTeam" class="float-right mr-2 color-primary pointer font-size14">{{$t('view-all')}}</h6>
          </v-col>
        </v-card-title>
        <v-card-text class="text-left" style="overflow-y: auto; max-height: 9vh;">
          <h6 class="my-2 font-size14">{{$t('primary-doctor')}}: {{ detailedUser.userData.medicalTeamsData[0].primaryDoctorFullName }}</h6>
          <v-col v-if="showMedicalTeam" class="pa-0">
          <span
              v-for="member in detailedUser.userData.medicalTeamsData[0].membersList"
              :key="member.id"
              class="d-block"
          >
            <span>{{member.firstName}} {{member.lastName}}</span>
          </span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-card>
</v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { fromTo } from '@/utils/unit-converter.js';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { doctorBlack, patientBlack } from '@/assets';
export default {
	data () {
		return {
      patientBlack: patientBlack,
			doctorBlack: doctorBlack,
      showMedicalTeam: false,
			filterChange: false,
			filterChat: 'LAST7',
		};
	},
	computed: {
		...mapState({
			detailedUser: (state) => state.users.detailedUser,
		}),
		...mapGetters({
      userSettings: 'authentication/getUserSettings',
      pageHeight: 'pageHeight',
    }),
    getLatestDiagnosis () {
      const diagnoses = this.detailedUser.userData.patientDiagnosisList.map(diagnosis => {
        return `<p class="mb-1"><b>${diagnosis.diagnosisCode}</b> ${diagnosis.diagnoseDescription}</p>`;
      });
      return `<div class="text-left px-0">${diagnoses.join('')}</div>`;
    },
    getTreatmentSummary () {
      const treatments = this.detailedUser.userData.patientMetadata.prescriptions;
      const formattedTreatments = `<p class="mb-1">${treatments}</p>`;
      return `<div class="text-left px-0">${formattedTreatments}</div>`;
    },
    getAllergies () {
      const metadataInfo = this.detailedUser.userData.patientMetadata.diagnose;
      const formattedMetadataInfo = `<p class="mb-1">${metadataInfo}</p>`;
      return `<div class="text-left">${formattedMetadataInfo}</div>`;
    },
	},
	async mounted () {
    if (this.detailedUser === null) {
      await this.getUserById();
    }
	},
	watch: {
		filterChat () {
			this.filterChange = true;
		},
		filterChanged (val) {
			this.filterCase = val;
			this.getMyNotifications(this.filterCase);
		},
	},

	methods: {
    triggerMedicalTeam () {
      this.showMedicalTeam = !this.showMedicalTeam;
    },
    formatHeightInFeet () {
      if (this.userSettings.Units === 'IMPERIAL') {
        const totalInches = Math.round(this.detailedUser.userData.patientMetadata.heightInCm / 2.54);
        const feet = Math.floor(totalInches / 12);
        const remainingInches = totalInches % 12;
        const tenthsOfFoot = remainingInches / 12;
        const heightInFeet = feet + tenthsOfFoot;
        return heightInFeet.toFixed(1) + ' ft';
      } else {
        return this.detailedUser.userData.patientMetadata.heightInCm + ' cm';
      }
    },
		synchronizeDrawers (drawer) {
			this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer);
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'patientdetails',
      });
		},
		phone: function (val) {
			if (val !== undefined) {
				this.phoneErrors = !isValidPhoneNumber(val);
				return new AsYouType().input(val);
			}
		},
		async getUserById () {
			this.userLoading = true;
			await this.$store
				.dispatch('users/getUserById', this.$route.params.id)
				.then(() => {
					this.$store.dispatch('patientdiagnosis/getDiagnosisListForPatient', this.$route.params.id);
					this.userLoading = false;
				});
		},
		toFeet (value) {
			return fromTo(value, 'cm', 'ft-us').toFixed(2);
		},
		toLbs (value) {
			return fromTo(value, 'kg', 'lb').toFixed(0);
		},
		calculateAge (dateString) {
			const today = new Date();
			const birthDate = new Date(dateString?.split('T')[0]);
			let age = today.getFullYear() - birthDate.getFullYear();
			const m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
	},
};
</script>
