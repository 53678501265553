<template>
  <div class="pa-1 ma-0 vitals-drawer-height">
    <div class="text-center d-flex align-center  px-3 pt-1 pb-3" >
      <v-icon
          :ripple="false"
          id="backBtn"
          color="#6600ff"
          class="pa-1 mr-3"
          @click="closeDrawer()"
      >mdi-chevron-left</v-icon>
      <v-img :src="require('@/assets/icons/'+ vitalsType.deviceIcon +'black.svg')" :alt="vitalsType.deviceName" aspect-ratios="1" max-width="22" class="mr-2"> </v-img>
      <span class="text-h6" >{{ $t(vitalsType.deviceName) }}</span>
    </div>
    <div
        class="pr-4 pl-2 pb-2" style="width: 100%; height: 100%; flex-direction: column;"
    >
      <div class="d-flex height45" id="VitalsGraph">
        <v-col id="VitalsGraph" :class="{'shrunkCol': switchToggle}" class="VitalsData pa-0 justify-space-between">
          <div
              id="InteractiveComponents"
              class="d-flex pa-3 height15"
          >
            <v-col class="pa-0" >
              <v-checkbox
                  :disabled="!periodData"
                  class="ma-0"
                  v-model="thresholdToggle"
                  :ripple="false"
                  :label="$t('prescribed_thresholds')"
                  @change="toggleThresholds"
              ></v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="pa-0" cols="4" sm="4" lg="4">
              <v-select
                  v-model="period"
                  :ripple="false"
                  :items="periodDurations"
                  @change="filterChange"
                  hide-details
                  dense
                  outlined
              ></v-select>
            </v-col>
          </div>
          <div class="height80">
            <VitalsGraph
                v-if="periodData"
                ref="vitalsGraph"
                :graphData="measurements"
                :thresholdsValues="thresholds"
                :extreamThreshHolds="extreamThreshHolds"
                :thresholdToggle="thresholdToggle"
                :unitSystem="getUnitSystem"
                :detailsToggle="switchToggle"
            ></VitalsGraph>
            <div v-if="!periodData" class="d-flex flex-column justify-center">
              <v-icon color="dark gray" x-large>mdi-chart-areaspline-variant</v-icon>
              <p class="text-body-1"> {{ $t("no-data-found") }} </p>
              <p class="text-caption gray-color">
                {{ $t( "filter-note" )}}
              </p>
            </div>
          </div>
        </v-col>
        <!--        <v-card-->
        <!--            v-if="switchToggle"-->
        <!--            id="VitalsDetails"-->
        <!--            class="VitalsData ml-2 px-1 py-2"-->
        <!--            cols='2'-->
        <!--            style="max-height: 400px; overflow: auto"-->
        <!--        >-->
        <!--&lt;!&ndash;          <span class="pa-0 font-weight-bold">{{ $t('reference') }}</span>&ndash;&gt;-->
        <!--          <v-row dense  v-for="item in items" :key="item.id"  class="px-2 mt-8 mb-6">-->
        <!--            <v-row dense :style="{borderLeft: '5px solid' + item.color}" v-if="deviceType === 3">-->
        <!--                <v-col cols="6" align-self="start" class="text-body-2 text-left">-->
        <!--                  <span class="pa-0 font-weight-bold">{{item.classification}}</span>-->
        <!--                  <div>-->
        <!--                    <span class="pa-0 text-body-2 font-weight-light">{{ item.systolicBloodPressure }}</span>-->
        <!--                  </div>-->
        <!--                </v-col>-->
        <!--            </v-row>-->
        <!--            <v-row v-else :style="{borderLeft: '5px solid' + item.color}" dense>-->
        <!--              <v-col cols="12" align-self="center" class=" text-left">-->
        <!--                <span class="pa-0 font-weight-bold">{{ item.description }}</span>-->
        <!--                <div>-->
        <!--                  <span class="pa-0 text-body-2 font-weight-light">{{ item.first_unit }}</span>-->
        <!--                </div>-->
        <!--              </v-col>-->
        <!--            </v-row>-->
        <!--          </v-row>-->
        <!--        </v-card>-->
      </div>
      <div
          class="VitalsData vitals-table-custom-height"
          id="VitalsDataTableCont"
      >
        <v-data-table
            :key="rerender"
            :headers="headers"
            :items="measurements"
            fixed-header
            class="elevation-0"
            id="VitalsDataTable"
            item-key="dateTime"
            :items-per-page="-1"
            sort-by="dateTime"
            dense
            height="500"
            hide-default-footer
            :footer-props="{
              disablePagination: false,
              }"
        >
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import VitalsGraph from '@/views/Client/components/VitalsGraph.vue';
import { formatterFilter } from '@/utils/luxon-formater';
import { mapGetters } from 'vuex';
export default {
  data () {
    return {
      thresholdToggle: true,
      switchToggle: false,
      timeLocalization: this.$i18n.locale,
      rerender: 1,
      periodDurations: [
        'Today',
        'Last 7 days',
        'Last 14 days',
        'Last 30 days',
        'All',
      ],
      period: 'Last 14 days',
      periodData: false,
    };
  },
  props: {
    vitalsType: null,
    unitSystem: null,
  },
  components: {
    VitalsGraph,
  },
  computed: {
    ...mapGetters({ pageHeight: 'pageHeight' }),
    deviceType () {
      return this.vitalsType.deviceType;
    },
    items () {
      const ICONS = {
        low: 'mdi-thermometer-low',
        normal: 'mdi-thermometer',
        advice: 'mdi-thermometer-plus',
        acceptable: 'mdi-thermometer-alert',
        urgent: 'mdi-thermometer-high',
      };
      const COLORS = [
        '#64B5F6',
        '#43A047',
        '#FFD740',
        '#FF6D00',
        '#DD2C00',
        '#90CAF9',
        '#BBDEFB',
      ];
      let items = [];
      switch (this.vitalsType.deviceType) {
        case 1:
          items = [
            {
              id: 1,
              color: COLORS[0],
              icon: ICONS.low,
              description: this.$t('low'),
              first_unit: '< 35°C   < 95.0°F ' + this.$t('or-less'),
            },
            {
              id: 2,
              color: COLORS[1],
              icon: ICONS.normal,
              description: this.$t('normal-readings'),
              first_unit: '(36.5-37.5)°C  (99.5-99.7)°F',
            },
            {
              id: 3,
              color: COLORS[2],
              icon: ICONS.acceptable,
              description: this.$t('home-monitoring'),
              first_unit: '(37.5-38)°C  (99.7-100.4)°F',
            },
            {
              id: 4,
              color: COLORS[3],
              icon: ICONS.advice,
              description: this.$t('advice'),
              first_unit: '(38.1-39)°C  (100.58-102.2)F',
            },
            {
              id: 5,
              color: COLORS[4],
              icon: ICONS.urgent,
              description: this.$t('call-emergency'),
              first_unit: '39°C  102.2°F ' + this.$t('or-more'),
            },
          ];
          break;
        case 2:
          items = [
            {
              id: 1,
              color: COLORS[1],
              icon: ICONS.urgent,
              description: this.$t('normal-readings'),
              first_unit: '96% ' + this.$t('or-more'),
            },
            {
              id: 2,
              color: COLORS[2],
              icon: ICONS.urgent,
              description: this.$t('home-monitoring'),
              first_unit: '95%',
            },
            {
              id: 3,
              color: COLORS[3],
              icon: ICONS.urgent,
              description: this.$t('advice'),
              first_unit: '93-94%',
            },
            {
              id: 4,
              color: COLORS[4],
              icon: ICONS.urgent,
              description: this.$t('call-emergency'),
              first_unit: '92% ' + this.$t('or-less'),
            },
          ];
          break;
        case 3:
          items = [
            {
              id: 1,
              color: COLORS[0],
              icon: ICONS.urgent,
              classification: this.$t('low'),
              systolicBloodPressure: '(<90/<60) mmHg',
            },
            {
              id: 2,
              color: COLORS[1],
              icon: ICONS.urgent,
              classification: this.$t('normal'),
              systolicBloodPressure: '(<120/<80) mmHg',
            },
            {
              id: 3,
              color: COLORS[2],
              icon: ICONS.urgent,
              classification: this.$t('prehypertension'),
              systolicBloodPressure: '(120-139/80-89) mmHg',
            },
            {
              id: 4,
              color: COLORS[3],
              icon: ICONS.urgent,
              classification: this.$t('stage-one-hypertension'),
              systolicBloodPressure: '(140-159/90-99) mmHg',
            },
            {
              id: 5,
              color: COLORS[4],
              icon: ICONS.urgent,
              classification: this.$t('stage-two-hypertension'),
              systolicBloodPressure: '(≥160/≥100) mmHg',
            },
          ];
          break;
        case 6:
          items = [
            {
              id: 1,
              color: COLORS[5],
              icon: ICONS.urgent,
              description: this.$t('severe-hypoglycemia'),
              first_unit: '<53 mg/dL  <3 mmol/L',

            },
            {
              id: 2,
              color: COLORS[0],
              icon: ICONS.urgent,
              description: this.$t('hypoglycemia'),
              first_unit: '<70 mg/dL <3.9 mmol/L',
            },
            {
              id: 3,
              color: COLORS[1],
              icon: ICONS.urgent,
              description: this.$t('normal'),
              first_unit: '<125 mg/dL  <7 mmol/L',
            },
            {
              id: 4,
              color: COLORS[3],
              icon: ICONS.urgent,
              description: this.$t('hyperglycemia'),
              first_unit: '<200 mg/dL <10 mmol/L',
            },
            {
              id: 5,
              color: COLORS[4],
              icon: ICONS.urgent,
              description: this.$t('diabetes'),
              first_unit: '(200-500+)mg/dL (10-27.7+)mmol/L',
            },
          ];
          break;
        case 7:
          items = [
            {
              id: 1,
              color: COLORS[5],
              icon: ICONS.urgent,
              description: this.$t('optimal'),
              first_unit: '<200 mg/dL  <5.2 mmol/L',
            },
            {
              id: 2,
              color: COLORS[1],
              icon: ICONS.urgent,
              description: this.$t('intermediate'),
              first_unit: '(200-239)mg/dL  (5.3-6.2)mmol/L',
            },
            {
              id: 3,
              color: COLORS[4],
              icon: ICONS.urgent,
              description: this.$t('high'),
              first_unit: '>239 mg/dL  >6.2mmol/L',
            },
          ];
          break;
      }
      return items;
    },
    extreamThreshHolds () {
      return JSON.parse(this.vitalsType.deviceDefaultExtremeValuesJson);
    },
    thresholds () {
      return JSON.parse(this.vitalsType.deviceDefaultValuesJson);
    },

    getUnitSystem () {
      return this.unitSystem;
    },

    headers () {
      const heads = [{ text: 'Date & Time', value: 'measuredAtFormatted',	class: 'table-header pl-4' }];

      switch (this.vitalsType.deviceName) {
        case 'cholesterol-meter':
          heads.push({ text: 'Mg/L', value: 'cholesterol_mgL',	class: 'table-header pl-5' });
          heads.push({ text: 'Mmol/L', value: 'cholesterol_mmolL',	class: 'table-header pl-3' });
          break;

        case 'glucose-meter':
          heads.push({ text: 'Mg/L', value: 'glucose_mgL',	class: 'table-header pl-5' });
          heads.push({ text: 'Mmol/L', value: 'glucose_mmolL',	class: 'table-header pl-3' });
          break;

        case 'blood-pressure':
          heads.push({ text: 'Sys', value: 'Sys',	class: 'table-header pl-4' });
          heads.push({ text: 'Dias', value: 'Dias',	class: 'table-header pl-3' });
          heads.push({ text: 'Pulse', value: 'Pul',	class: 'table-header pl-2' });
          break;

        case 'thermometer':
          heads.push({ text: 'C°', value: 'Temperature_C',	class: 'table-header pl-6' });
          heads.push({ text: 'F°', value: 'Temperature_F',	class: 'table-header pl-6' });
          break;

        case 'weight-scale':
          heads.push({ text: 'Kg', value: 'weight_kg',	class: 'table-header pl-6' });
          heads.push({ text: 'Lb', value: 'weight_lbs',	class: 'table-header pl-6' });
          break;

        case 'oximeter':
          heads.push({ text: 'Sp02', value: 'Spo2',	class: 'table-header pl-3' });
          heads.push({ text: 'Bpm', value: 'BPM',	class: 'table-header pl-3' });
          heads.push({ text: 'Pi', value: 'PI',	class: 'table-header pl-5' });
          break;

        default:
          break;
      }

      return heads;
    },

    measurements () {
      const values = [];
      const filteredDate = this.filterDate();
      // this.debug(['FilterDateComputed', filteredDate]);
      this.vitalsType.measurementsData.forEach((data) => {
        const parsedData = JSON.parse(data.measuredDataJson);
        const measurementDate = new Date(data.measuredAt);
        // this.debug(this.filterDate);
        // this.debug(measurementDate);
        // measuredDataJson: '{"Temperature_C":36.899999999999999, "TemperatureBodyType":2,"Temperature_F":98.299999999999997,"device_id":"0aac55da-5102-4dd5-a770-129d4cc68d0a","device_type":"1"}'
        // measuredDataJson: '{"MeasurementStatus":1,"device_id":"C6:05:52:00:E8:02","device_type":"6","glucose_mgL":110.0,"glucose_mmolL":6.1,"time":"2023-02-20T09:53:51.523"}'
        if (measurementDate > filteredDate) {
          switch (parsedData.device_type) {
            case '1':
              parsedData.Temperature_C = parseFloat(parsedData.Temperature_C).toFixed(1);
              parsedData.Temperature_F = parseFloat(parsedData.Temperature_F).toFixed(1);
              break;
            case '2':
              parsedData.Spo2 = Number(parsedData.Spo2).toFixed();
              parsedData.PI = Number(parsedData.PI).toFixed(1);
              parsedData.BPM = Number(parsedData.BPM).toFixed();
              break;
            case '3':
              parsedData.Dias = Number(parsedData.Dias.toFixed());
              parsedData.Sys = Number(parsedData.Sys.toFixed());
              parsedData.Pul = Number(parsedData.Pul.toFixed(1));
              break;
            case '5':
              parsedData.weight_kg = Number.parseFloat(parsedData.weight_kg).toFixed(1);
              parsedData.weight_lbs = Number.parseFloat(parsedData.weight_lbs).toFixed(1);
              break;
            case '6':
              parsedData.glucose_mmolL = Number.parseFloat(parsedData.glucose_mmolL).toFixed(2);
              parsedData.glucose_mgL = Number.parseFloat(parsedData.glucose_mgL).toFixed(1);
              break;
            case '7':
              parsedData.cholesterol_mmolL = Number.parseFloat(parsedData.cholesterol_mmolL).toFixed(2);
              parsedData.cholesterol_mgL = Number.parseFloat(parsedData.cholesterol_mgL).toFixed(1);
              break;

            default:
              // Handle default case or unknown device_type
              // ...
              break;
          }
          parsedData.measuredAt = data.measuredAtUtc;
          parsedData.measuredAtFormatted = formatterFilter(
              data.measuredAtUtc,
              'datetime_med',
              this.timeLocalization,
              null,
          );
          values.push(parsedData);
        }
      });

      if (values.length === 0) {
        this.setNODataVariables(false);
        this.debug(['FilteredValues', values]);
      } else {
        this.setNODataVariables(true);
      }

      return values;
    },
  },
  methods: {

    toggleThresholds () {
      this.$refs.vitalsGraph.toggleThresholds();
    },

    toggleDetails () {
      // this.switchToggle = !this.switchToggle;
      // this.$refs.vitalsGraph.toggleDetails();
    },

    closeDrawer () {
      this.$emit('close');

      // Check if vitalsGraph is defined before calling filterChange
      if (this.$refs.vitalsGraph) {
        this.$refs.vitalsGraph.filterChange();
      }
    },

    debug (data) {
    },

    setNODataVariables (state) {
      this.periodData = state;
    },

    filterChange () {
      this.rerender = !this.rerender;
      this.$nextTick(() => {
        // eslint-disable-next-line no-unused-expressions
        this.$refs.vitalsGraph?.filterChange?.();
      });
    },

    filterDate () {
      const filter = this.period;
      const td = new Date();
      var filteredDate = null;
      switch (filter) {
        case 'Today':
          filteredDate = new Date(td.getFullYear(), td.getMonth(), td.getDate());
          break;

        case 'Last 7 days':
          filteredDate = new Date(td.getFullYear(), td.getMonth(), td.getDate() - 6);
          break;

        case 'Last 14 days':
          filteredDate = new Date(td.getFullYear(), td.getMonth(), td.getDate() - 13);
          break;

        case 'Last 30 days':
          filteredDate = new Date(td.getFullYear(), td.getMonth(), td.getDate() - 29);
          break;
        default:
          filteredDate = new Date(td.getFullYear() - 1000, td.getMonth(), td.getDate());
          break;
      }

      return filteredDate;
    },

  },
};
</script>
