<template>
  <v-data-table
      class="my-4 mr-3 ml-2"
      :items="filteredNotes"
      :height="pageHeight(129)"
      dense
      style="border: 1px solid #e1dce8; border-radius: 5px"
      :items-per-page="10000000"
      hide-default-footer
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
  >
		<!-- Table item slot -->
		<template v-slot:item="{ item }">
			<tr class="custom-row" v-if="item">
				<td class="px-2 pt-0">
					<v-row dense class="py-0 mt-0">
					<v-col class="d-flex align-center justify-start pl-1 py-0" cols="6">
						<span v-if="item?.userData" class="font-bold" style="font-size: 14px"> {{ item.userData.firstName + ' ' + item.userData.lastName }}</span>
					</v-col>
					<v-col class="d-flex align-center justify-end pr-1 py-0" cols="6">
						<span style="font-size: 14px; color: gray; text-transform: capitalize;">{{ new Date(item.noteTimestamp).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric'}) }}</span>
						<v-menu
							class="py-0"
							right
							bottom
							offset-y
							offset-x
							transition="slide-x-transition"
						>
						<template v-slot:activator="{on, attrs}">
							<v-btn
								icon
								color="black"
								v-bind="attrs"
								v-on="on"
							>
							<span class="mdi mdi-24px mdi-dots-horizontal"></span>
							</v-btn>
						</template >
						<v-list class="px-0" dense>
							<v-list-item class="py-0 my-0" link dense @click="synchronizeDrawers('editNote', item)">
							<v-list-item-icon  class="mx-1">
								<v-icon color="black" class="icon-img mdi-18px">mdi-pencil</v-icon>
							</v-list-item-icon>
							<v-list-item-content class="font-bold" style="font-size: 14px">{{ $t('edit') }}</v-list-item-content>
							</v-list-item>
							<v-divider></v-divider>
							<v-list-item @click="deleteNote(item)" class="py-0 my-0" link dense>
							<v-list-item-icon class="mx-1">
								<v-icon color="red" class="mdi mdi-trash-can-outline mdi-18px"></v-icon>
							</v-list-item-icon>
							<v-list-item-content  class="font-bold" style="font-size: 14px">{{$t('delete')}}</v-list-item-content>
							</v-list-item>
						</v-list>
						</v-menu>
					</v-col>
					<v-col cols="12" class="py-0">
						<p class="" style="font-size: 14px; text-align: left;" v-html="item.noteDescription">
						</p>
					</v-col>
					</v-row>
				</td>
			</tr>
		</template>
		<!-- Table top slot -->
		<template v-slot:top>
			<v-toolbar style="border: 1px solid #e1dce8; border-top-left-radius: 5px;border-top-right-radius: 5px" flat height="60" >
				<v-row dense>
					<v-col cols="4">
						<v-text-field
							append-icon="mdi-magnify"
							:label="$t('search')"
							dense
							outlined
							class="search-field pr-1"
							hide-details
							v-model="search"
						></v-text-field>
					</v-col>
					<v-col cols="8" align-self="center" class="d-flex justify-end">
						<v-btn color="chips" style="text-transform: capitalize; color:white;" :ripple="false" elevation="0" @click="synchronizeDrawers('addNote')">+ {{$t('note')}} </v-btn>
					</v-col>
				</v-row>
			</v-toolbar>
		</template>
	</v-data-table>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
	components: {
	},
	data () {
		return {
      sortBy: 'noteTimestamp',
      sortDesc: true,
			loading: false,
			searchNote: null,
			patientId: null,
			showNotesDialog: false,
			activeTab: 'notes',
			headers: [
				{ text: ' ', value: 'name' },
				{ text: ' ', value: 'date' },
			],
			search: '',
		};
	},
	computed: {
		...mapState({
			notesFromRepo: (state) => state.appointments.notes,
			usersFromRepo: (state) => state.users.users,
			patientsFromRepo: (state) => state.hospitals.patients,
			relatedPatients: (state) => state.hospitals.relatedPatients,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
		}),
		...mapGetters({
			getNotes: 'appointments/getNotes',
			refreshNotes: 'appointments/refreshNotes',
			roleName: 'authentication/getRole',
			hospitalId: 'authentication/getHospitalId',
			myMedicalTeam: 'authentication/getMyMedicalTeam',
			pageHeight: 'pageHeight',
			delay: 'authentication/getDelay',
			appBarSearch: 'filterbar/getSearch',
		}),
		editDetailsDrawer () {
			return this.$store.state.viewDetailsDrawer;
		},
		getNotesArray () {
			return Object.values(this?.notesFromRepo);
		},
		filteredNotes () {
			const searchLowerCase = this.search.toLowerCase();
			return this.notesFromRepo.filter(note => {
				const noteDescription = note.noteDescription.toLowerCase();
				return (
					noteDescription.includes(searchLowerCase)
				);
			});
		},
		hasNotes () {
			return !this.notesFromRepo?.length;
		},
	},
	mounted () {
		this.getAllNotes();
	},
	methods: {
		deleteNote (id) {
			const body = {
				noteId: id.noteId,
			};
			this.$store.dispatch('appointments/deleteNote', body).then(() => {
				this.getAllNotes();
			});
		},
		openAddDrawer (drawer) {
			this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer);
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', !this.updateDrawer);
		},
		async synchronizeDrawers (drawer, item) {
			if (drawer === 'addNote') {
				this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer);
			} else {
				this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer, item);
				await this.$store.dispatch('appointments/getNotesById', item?.noteId);
			}
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'patientdetails',
      });
		},
		async getAllNotes () {
			this.loading = true;
			return await this.$store.dispatch('appointments/getNotesWithPatient', this.$route.params.id);
		},
	},
};
</script>
<style scoped>
.custom-header td {
  background-color: white !important;
}
.search-field {
  display: flex;
  align-items: center;
}
</style>
