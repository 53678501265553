<template >
  <div class="pl-2 pr-3 py-0 d-flex justify-center align-center overflow-x-hidden">
    <div style="min-height: 156px;" v-if="vitals.length === 0 && Array.isArray(surveyForPatientReport) && surveyForPatientReport.length <= 0" class="d-flex align-center justify-center" >
        <span  style="border: #e3e3e3 dashed 2px; color:#a3a3a3" class="pa-3 text-subtitle-1">
            {{ $t("thisUserHasNoDevices") }}
        </span>
    </div>
    <div class="d-flex overflow-auto">
      <v-card
          v-for="(item, i) in vitals"
          :key="i"
          color="white"
          hover
          elevation-4
          @click="toggleDrawer(i)"
          :ripple="false"
          class="mx-1 mb-4 border-radius15"
      >
        <v-sheet
            color="white"
            elevation="1"
            height="130"
            width="180"
            class="d-flex align-center"
        >
          <v-row class="d-flex align-center" dense>
            <v-col class="d-flex align-center justify-center" cols="12">
              <v-img :src="require('@/assets/icons/'+ item.deviceIcon +'black.svg')" :alt="item.deviceName" max-width="18"></v-img>
              <span class="color-black">&nbsp;{{ $t(item.deviceName) }}</span>
            </v-col>
             <v-col cols="12">
              <div v-if="item.measurementsData.length > 0" style="height: 100%;!important" class="d-flex flex-column align-center">
              <span class=" ma-0 py-0 black--text font-weight-bold">{{ lastMeasurement(item) }}</span>
              <span class="pt-4 font-size14">{{ lastMeasuredTime(item) }}</span>
              </div>
              <v-col class='pa-4' v-else>
              <span class="pa-2 color-primary border-primary">{{$t("no-recorded-data")}}</span>
              </v-col>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card>
      <v-card @click="openAssessments(index)"  v-for="(survey, index) in surveyForPatientReport" :key="survey.id" style="min-width: 180px; max-width: 180px; height: 130px" class="mr-0 ml-2 overflow-y-auto border-radius15">
        <h6 class="mt-5 color-black">{{survey.templateName}}</h6>
        <h6 class="mt-3">{{ survey.recurrenceRuleOfLastRecurrence }}</h6>
      </v-card>

    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
export default {
  data () {
    return {
      selectedVitalsDrawer: false,
      vitals: [],
      surveys: [],
      isDrawerOpen: false,
      clickedItem: null,
      expandedRows: [],
      drawerContent: null,
      selectedAssessment: null,
      dialogVisible: false,
      loading: true,
      items: [{ hidden: true }, { hidden: true }],
    };
  },
  computed: {
    headersAnswer () {
      return [
        { text: 'Trigger alert on this', value: 'checkbox', sortable: true, width: '30%' },
        { text: 'Name', value: 'answer', width: '50%' },
      ];
    },
    ...mapState({
      detailedUser: (state) => state.users.detailedUser,
      hubs: (state) => state.nosessionhubs.hubs,
      surveyForPatient: (state) => state.mentalhealth.surveyPatentDetails,
      surveyForPatientReport: (state) => state.mentalhealth.surveyReportForPatient,
    }),
    ...mapGetters({ roleName: 'authentication/getRole', userSettings: 'authentication/getUserSettings' }),
    headerForUser () {
      return [
        {
          text: this.$t('date'),
          value: 'submitAtDate',
          width: '80%',
        },
        {
          text: this.$t('is-completed'),
          value: 'isCompleted',
          width: '10%',
        },
      ];
    },
    assignedMedicalDevices () {
      return this.hubs?.assignedMedicalDevicesList?.map((item) => ({
        deviceDefaultValuesJson: item.deviceDefaultValuesJson,
        deviceDefaultExtremeValuesJson: item.deviceDefaultExtremeValuesJson,
        deviceType: item.deviceType,
        measurementsData: item.measurementsData,
        deviceName: item.deviceName,
      }));
    },
  },
  methods: {
    formatDate (submitAtDate) {
      const date = new Date(submitAtDate);
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
    openAssessments (index) {
      this.$emit('toggledrawer2', { isOpen: true, selectedSurvey: this.surveyForPatientReport[index] });
      this.clickedItem = index;
      this.drawerContent = null;
      this.selectedAssessment = this.surveyForPatientReport[index];
      this.isDrawerOpen = true;
    },
    async getSurveys () {
      const headers = {
        patientId: this.detailedUser.userData.id,
        filterCase: 'ALL',
        limitForOccurrences: 50,
      };
     await this.$store.dispatch('mentalhealth/getSurveyReportForPatient', headers);
    },
    formatElapsedTime (startDate) {
      const startDateObject = new Date(startDate);
      const currentDateObject = new Date();
      const timeDifference = currentDateObject - startDateObject;
      const secondsDifference = Math.floor(timeDifference / 1000);
      const minutesDifference = Math.floor(secondsDifference / 60);
      const hoursDifference = Math.floor(minutesDifference / 60);
      const daysDifference = Math.floor(hoursDifference / 24);
      if (daysDifference >= 7) {
        const weeksDifference = Math.floor(daysDifference / 7);
        return `${weeksDifference} ${weeksDifference === 1 ? this.$t('week') : this.$t('weeks')} old`;
      } else if (daysDifference >= 1) {
        return `${daysDifference} ${daysDifference === 1 ? this.$t('day') : this.$t('days')} old`;
      } else if (hoursDifference >= 1) {
        return this.$t('dayOld');
      } else if (minutesDifference >= 1) {
        return this.$t('hourOld');
      } else {
        return this.$t('secondOld');
      }
    },
    toggleDrawer (index) {
      if (this.assignedMedicalDevices[index].measurementsData.length > 0) {
        // this.$store.commit('SET_GRAPH_DATA', this.assignedMedicalDevices[index]);
        this.selectedVitalsDrawer = this.vitals[index];
        this.$emit('toggledrawer', this.selectedVitalsDrawer);
      }
    },
    async getHubsData (data) {
      await this.$store.dispatch('nosessionhubs/getHubsData', data).then(
          this.loading = false);
    },

    fixVitalnames () {
      this.vitals = this.assignedMedicalDevices;
      for (const device in this?.vitals) {
        switch (this?.vitals[device]?.deviceType) {
          case 3:
            this.vitals[device].deviceIcon = 'blood_pressure_';
            this.vitals[device].deviceName = 'blood-pressure';
            break;
          case 6:
            this.vitals[device].deviceIcon = 'glucose_meter_';
            this.vitals[device].deviceName = 'glucose-meter';
            break;
          case 1:
            this.vitals[device].deviceIcon = 'thermometer_';
            this.vitals[device].deviceName = 'thermometer';
            break;
          case 2:
            this.vitals[device].deviceIcon = 'oxymeter_';
            this.vitals[device].deviceName = 'oximeter';
            break;
          case 5:
            this.vitals[device].deviceIcon = 'scale_';
            this.vitals[device].deviceName = 'weight-scale';
            break;
          case 7:
            this.vitals[device].deviceIcon = 'cholesterol_meter_';
            this.vitals[device].deviceName = 'cholesterol-meter';
            break;

          default:
            break;
        }
      }
    },

    timeAgo (date) {
      if (!date) {
        return;
      }
      if (typeof date !== 'object') {
        date = new Date(date);
      }

      const seconds = Math.floor((new Date() - date) / 1000);
      let intervalType;

      let interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        intervalType = this.$t('year');
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          intervalType = this.$t('month');
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            intervalType = this.$t('day');
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              intervalType = this.$t('hour');
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                intervalType = this.$t('minute');
              } else {
                interval = seconds;
                intervalType = this.$t('second');
              }
            }
          }
        }
      }
      if (interval > 1 || interval === 0) {
        intervalType += 's';
      }
      const temp = intervalType + ' ' + this.$t('ago');
      const result = {
        intervalValue: interval,
        intervalType: temp,
      };
      return result;
    },

    lastMeasurement (device) {
      if (device.measurementsData.length === 0) {
        return;
      }
      const measurements = JSON.parse(device.measurementsData[0].measuredDataJson);

      switch (device.deviceName) {
        case 'thermometer':
          if (this.userSettings.Units.toLowerCase() === 'metric') {
            return measurements?.Temperature_C + ' °C';
          } else if (this.userSettings.Units.toLowerCase() === 'imperial') {
            return measurements.Temperature_F + ' °F';
          }
          break;
        case 'blood-pressure':
          return measurements.Dias !== undefined ? measurements.Sys + '/' + measurements.Dias + ' mmHg' : '';
        case 'oximeter':
          return measurements?.BPM !== undefined ? measurements?.Spo2 + ' SpO2 ' + measurements?.BPM + ' bpm ' + ' Pi ' + (measurements?.PI).toFixed(2) + ' %' : '';
        case 'weight-scale':
          if (this.userSettings.Units.toLowerCase() === 'metric') {
            return measurements.weight_kg + ' kg';
          } else return measurements.weight_lbs + ' lb';
        case 'glucose-meter':
          if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
            return measurements.glucose_mmolL + ' mmol/L';
          } else return measurements.glucose_mgL + ' mg/dL';
        case 'cholesterol-meter':
          if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
            return measurements.cholesterol_mmolL + ' mmol/L';
          } else return measurements.cholesterol_mgL + ' mg/dL';
      }
    },

    lastMeasuredTime (device) {
      const result = this.timeAgo(device.measurementsData[0]?.measuredAt);
      return result.intervalValue + ' ' + result.intervalType;
    },
  },

  async mounted () {
    const data = {
      id: this.detailedUser.userData.id,
      filter: 'ALL',
    };
    await this.getHubsData(data);
    this.fixVitalnames();
    await this.getSurveys();
  },
};
</script>
