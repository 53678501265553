<template>
  <div>
	<div v-if="treatmentPlansFromRepo.length === 0 && surveyForPatientReport.length === 0" class="d-flex align-center justify-center" style="">
		<span style="border: #e3e3e3 dashed 2px; color:#a3a3a3" class="pa-3 text-subtitle-1">
		{{ $t("thisUserHasNoDevices") }}
		</span>
    </div>
    <v-row class="mb-5 mt-3 mr-3 ml-2 pa-2 elevation-3" dense style="background-color:white;border: 1px solid #e1dce8; border-radius: 5px">
		<div v-for="item in treatmentPlansFromRepo" :key="item.id" style="width:100%;">
			<div :key="item.id" v-if="item.status === 1" >
			<div class="ma-0 pa-0">
				<v-col class="d-flex justify-start align-center pa-2 pb-0" cols="12">
				<v-row>
					<v-col class="text-left pb-0 d-flex justify-space-between" cols="12">
					<span class="font-bold" style="color:black;"> {{item.tpName}}</span>
					<div class="d-flex align-center">
						<div class="pt-1">
						<v-icon small class="mr-1" style="margin-top:-2px;" :style="detailedUser.userData.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
						<span style="font-weight: bold; color: #09CB09" class="mr-2">
				{{ $t('active-caps') }}
				</span>
						</div>
						<v-menu
							v-if="treatmentPlansFromRepo.length !== 0 && treatmentPlansFromRepo[0].patientId !== null"
							class="py-0"
							right
							bottom
							offset-y
							offset-x
							transition="slide-x-transition"
						>
						<template v-slot:activator="{on, attrs}">
							<v-btn
								class="mt-1"
								icon
								color="black"
								v-bind="attrs"
								v-on="on"
							>
							<span class="mdi mdi-24px mdi-dots-horizontal"></span>
							</v-btn>
						</template >
						<v-list class="px-2" dense>
							<v-list-item link dense @click="sinhronizeDrawer">
                <v-icon color="main_black" class="pr-1">mdi-pencil</v-icon>
							<v-list-item-content class="font-weight-bold font-size14">{{ $t('edit') }}</v-list-item-content>
							</v-list-item>
						</v-list>
              <v-list class="px-2" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item class="py-0 my-0" link dense @click="assignpatienttodefaultclientmonitoringplan" v-on="on">
                      <img :src="MonitoringPLanBlack" style="width:25px;" class="mr-2">
                      <v-list-item-content class="font-bold" style="font-size: 14px">{{$t('asssign-cmp')}}</v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>{{$t('assign-to-default-client-monitoring')}}</span>
                </v-tooltip>
              </v-list>
						</v-menu>
            <v-menu
                v-else
                class="py-0"
                right
                bottom
                offset-y
                offset-x
                transition="slide-x-transition"
            >
              <template v-slot:activator="{on, attrs}">
                <v-btn
                    class="mt-1"
                    icon
                    color="black"
                    v-bind="attrs"
                    v-on="on"
                >
                  <span class="mdi mdi-24px mdi-dots-horizontal"></span>
                </v-btn>
              </template >
              <v-list class="px-2" dense>
                <v-list-item class="py-0 my-0" link dense @click="deassignpatientfromclientmonitoringplan">
                  <img :src="MonitoringPLanBlack" style="width:25px;" class="mr-2">
                  <v-list-item-content class="font-bold" style="font-size: 13px">{{$t('deAssignTreatmentPlan')}}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

          </div>
					</v-col>
					<v-col class="text-left pt-0" cols="6">
					<span class="text--secondary font-weight-bold" style="font-size: 14px">{{$t('started')}}: {{ new Date(item.started).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) }}</span>
					</v-col>
					<v-col class="text-left pt-0" cols="6">
					<span v-if="item.ended !== null" class="text--secondary" style="font-size: 14px">{{$t('ended')}}: {{ new Date(item.ended).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}}</span>
					</v-col>
					<v-col class="text-left pt-2" cols="12">
					<span class="" style="font-size: 16px"> {{item.tpDescription}}</span>
					</v-col>
				</v-row>
				</v-col>
			</div>

			<div class="mt-10">
			<div style="display: flex; overflow-x: auto; text-align:center;">
				<div v-for="survey in surveyForPatientReport" :key="survey.id" >
				<div class="d-flex text-center">
					<img :src="wellnessIcon" style="min-width:20px; max-width: 20px" class="mr-1 ml-2" />
					<h6 class="font-weight-bold text-left color-black" style="font-size: 15px; min-width:150px;">{{survey.templateName}}</h6>
				</div>
				<div class="font-weight-bold text-left" style="color:gray; font-size: 15px; margin-left:33px;">{{$t('measure')}} {{survey.recurrenceRuleOfLastRecurrence}}</div>
				<div class="font-weight-bold text-left" style="color:gray; font-size: 15px; margin-left:33px;">
					{{ new Date(survey.timestampOfLastRecurrence).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) }}
				</div>
				</div>
				<div v-for="device in item.treatmentPlanDevicesList" :key="device.id" style="min-width:200px; " >
					<div class="d-flex align-center">
						<img class="mr-2" v-if="device.deviceTypeTitle === 'Blood pressure'" src="@/assets/icons/blood_pressure_black.svg" alt="Blood Pressure Icon" style="width: 20px;">
						<img class=" mr-2 mt-1" v-else-if="device.deviceTypeTitle === 'Oximeter'" src="@/assets/icons/oxymeter_black.svg" alt="Oximeter Icon" style="width: 20px;">
						<img class=" mr-2 mt-1" v-else-if="device.deviceTypeTitle === 'Thermometer'" src="@/assets/icons/thermometer_black.svg" alt="Thermometer Icon" style="width: 20px;">
						<img class=" mr-2 mt-1" v-else-if="device.deviceTypeTitle === 'Weight scale'" src="@/assets/icons/scale_black.svg" alt="Weight Scale Icon" style="width: 20px;">
						<img class=" mr-2 mt-1" v-else-if="device.deviceTypeTitle === 'Cholesterol Meter'" src="@/assets/icons/cholesterol_meter_black.svg" alt="Cholesterol Meter Icon" style="width: 20px;">
						<img class=" mr-2 mt-1" v-else-if="device.deviceTypeTitle === 'Blood glucose'" src="@/assets/icons/glucose_meter_black.svg" alt="Blood Glucose Icon" style="width: 20px;">
						<span class="font-weight-bold color-black">{{$i18n.locale === 'en' ? device.deviceTypeTitle : device.deviceTypeTitleEsp}}</span>
					</div>
					<span class="text-left font-weight-bold ml-n12" style="font-size: 15px; color:gray">{{$t('measure')}} {{ getLocalizedMeasurementPeriod(device.measurementPeriod) }}</span>
					<v-col cols="12" class="pt-0 pl-0">
					<v-chip-group>
						<v-row dense>
						<v-col v-if="device.measurementsCount === 1">
							<v-chip small label class="dark_purple ml-n11 text-color-white font-bold" >{{ getTimeWithAMPM(device.time1) }}</v-chip>
						</v-col>
						<v-col v-else-if="device.measurementsCount === 2">
							<v-chip small label class="ma-0 dark_purple text-color-white font-bold" >{{ getTimeWithAMPM(device.time1) }}</v-chip>
							<v-chip small label class="ma-0 dark_purple text-color-white font-bold" >{{ getTimeWithAMPM(device.time2) }}</v-chip>
						</v-col>
						<v-col v-else-if="device.measurementsCount === 3">
							<v-chip small label class="ma-0 dark_purple text-color-white font-bold" >{{ getTimeWithAMPM(device.time1) }}</v-chip>
							<v-chip small label class="ma-0 dark_purple text-color-white font-bold" >{{ getTimeWithAMPM(device.time2) }}</v-chip>
							<div >
							<v-chip small label class="dark_purple text-color-white font-bold" style="margin-left:-66px;">{{ getTimeWithAMPM(device.time3) }}</v-chip>
							</div>
						</v-col>
						<v-col v-else-if="device.measurementsCount === 4">
							<v-chip small label class="ma-0 dark_purple text-color-white font-bold" >{{ getTimeWithAMPM(device.time1) }}</v-chip>
							<v-chip small label class="ma-0 dark_purple text-color-white font-bold" >{{ getTimeWithAMPM(device.time2) }}</v-chip>
							<div class="mt-1">
							<v-chip small label class="ma-0 dark_purple text-color-white font-bold" >{{ getTimeWithAMPM(device.time3) }}</v-chip>
							<v-chip small label class="ma-0 dark_purple text-color-white font-bold" >{{ getTimeWithAMPM(device.time4) }}</v-chip>
							</div>
						</v-col>
						</v-row>
					</v-chip-group>
					</v-col>
				</div>

			</div>
			</div>
			</div>
		</div>
    </v-row>
    <!--     ACTIVE ENDS-->
    <v-row dense class="d-flex justify-end align-center pr-4">
      <v-col class="text-left font-bold ml-3">
<!--        <span style="font-size: 18px">{{ $t('inactive-monitoring-plans') }}</span>-->
      </v-col>
      <v-btn
		v-if="treatmentPlansFromRepo.length !== 0 && treatmentPlansFromRepo[0].patientId !== null"
		color="chips"
		dark
		style="color:white;"
		large
		@click="sinhronizeDrawers"
      >+ {{$t('monitoring-plan') }}
      </v-btn>
    </v-row>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { wellnessAssessmentPrimary, letterA, letterM, duplicate, monitoringPlanBlack } from '@/assets';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';
export default {
	props: ['computedPatientId'],
	data () {
		return {
      wellnessIcon: wellnessAssessmentPrimary,
			duplicate: duplicate,
			getPage: '',
			treatmentPlans: [],
			activeTab: 'abnormal',
			letterM: letterM,
			letterA: letterA,
      MonitoringPLanBlack: monitoringPlanBlack,
			assignDevices: [],
			status: '',
			idPatient: null,
			tpName: [],
			tpDescription: '',
			titleDesc: '',
			treatmentPlanDevicesList: [],
			monitoringPlan: [],
			createDropdownStatus: false,
			filteredDevices: [],
			createdevice: [],
			monitoringDevice: [],
			expandedMonitoringItem: [],
			expanded: [],
			headers: [
				{
					text: this.$t('device-type'),
					align: 'start',
					value: 'name',
				},
				{
					text: this.$t('manufacturer'),
					align: 'end',
					value: 'add',
				},
				{
					sortable: false,
					align: 'end',
					value: 'add',
				},
			],
			headers2: [
				{
					align: 'start',
					sortable: false,
					value: 'deviceTypeTitle',
					width: '0%',
				},
				{
					text: this.$t('device-type'),
					align: 'start',
					value: 'deviceTypeTitle',
				},
			],
			loader: null,
			loading: false,
			loadingForm: false,
			time1: null,
			menu1: false,
			modal1: false,
			time2: null,
			menu2: false,
			modal2: false,
			time3: null,
			menu3: false,
			modal3: false,
			time4: null,
			menu4: false,
			modal4: false,
			selectedDevice: null,
			treatmentSection: 0,
			deviceManufacturers: [],
			manufacturer: null,
			trustedHubIdentifier: null,
			selectiontimes: [
				{ name: this.$t('one-time-per-day'), id: 1 },
				{ name: this.$t('two-time-per-day'), id: 2 },
				{ name: this.$t('three-time-per-day'), id: 3 },
				{ name: this.$t('four-time-per-day'), id: 4 },
			],
		};
	},
	computed: {
		...mapState({
			authenticated: state => state.authentication.userData,
			detailedUser: (state) => state.users.detailedUser,
			devicetemplates: state => state.medicaldevice.deviceTemplates,
			treatmentPlansFromRepo: (state) => state.treatmentplan.treamentPlans,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
			patientActiveTreatmentPlan: (state) => state.medicaldevice.patientActiveTreatmentPlan,
			surveyForPatientReport: (state) => state.mentalhealth.surveyReportForPatient,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
			hospitalId: 'authentication/getHospitalId',
			myMedicalTeam: 'authentication/getMyMedicalTeam',
			pageHeight: 'pageHeight',
			delay: 'authentication/getDelay',
			appBarSearch: 'filterbar/getSearch',
			userSettings: 'authentication/getUserSettings',
			getRole: 'authentication/getRole',
		}),
		hasInactiveItems () {
			return this.treatmentPlansFromRepo.some(item => item.status === 0);
		},
	},
	pathname () {
		return this.$route.name;
	},
	updateDrawer () {
		return this.$store.state.updateDrawer;
	},
	concentration () {
		return this.userSettings?.Concentration;
	},
	unit () {
		return this.userSettings?.Units;
	},
	userData () {
		return this.detailedUser;
	},
	trustedHubs () {
		return this.userData?.trustedHubsData ?? [];
	},
	valid () {
		if ([1, 6].includes(this.deviceType)) {
			if ((this.value1 === '' || this.value1 === null) ||
                  (this.value2 === '' || this.value2 === null)) {
				return true;
			}
			return false;
		} else if (this.deviceType === 3) {
			if ((this.value1 === '' || this.value1 === null) ||
                  (this.value2 === '' || this.value2 === null) ||
                  (this.value3 === '' || this.value3 === null) ||
                  (this.value4 === '' || this.value4 === null) ||
                  (this.value5 === '' || this.value5 === null) ||
                  (this.value6 === '' || this.value6 === null)
			) {
				return true;
			}
			return false;
		} else if (this.deviceType === 7) {
			if ((this.value1 === '' || this.value1 === null) ||
                  (this.value2 === '' || this.value2 === null) ||
                  (this.value3 === '' || this.value3 === null) ||
                  (this.value4 === '' || this.value4 === null)) {
				return true;
			}
			return false;
		} else if (this.deviceType === 2) {
			if ((this.value0 === '' || this.value0 === null) ||
                  (this.value1 === '' || this.value1 === null) ||
                  (this.value2 === '' || this.value2 === null) ||
                  (this.value3 === '' || this.value3 === null) ||
                  (this.value4 === '' || this.value4 === null)) {
				return true;
			}
			return false;
		}
		return false;
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.save()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		async createdevice (val) {
			await this.$store.dispatch('medicaldevice/createMedicalDevice', val).then(() => {
				this.initialize();
			});
		},
		async manufacturer (val) {
			if (val) {
				await this.deviceTreatmentPlan(this.localDeviceManufacturer);
			}
		},
	},
	async mounted () {
		const header = { domainsList: 'MEDICAL_DEVICE_MANUFACTURER' };
		await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header);
		await this.initialize();
    await this.getSurveys();
	},
	methods: {
      deassignpatientfromclientmonitoringplan () {
      const body = {
        treatmentPlanId: this.treatmentPlansFromRepo[0].id,
        patientUserId: this.detailedUser.userData.id,
      };
        this.$store.dispatch('treatmentplan/deassignpatientfromclientmonitoringplan', body).then(res => {
          if (res.resFlag) {
            showSuccessAlert(this.$t('success-operation'));
            this.initialize();
            this.getSurveys();
          } else {
            showErrorAlert(this.$t('failed-operation'));
          }
        });
    },
    assignpatienttodefaultclientmonitoringplan () {
      const body = {
        patientUserId: this.detailedUser.userData.id,
      };
      this.$store.dispatch('treatmentplan/assignpatienttodefaultclientmonitoringplan', body).then(res => {
        if (res.resFlag) {
          showSuccessAlert(this.$t('success-operation'));
          this.initialize();
          this.getSurveys();
        } else {
          showErrorAlert(this.$t('failed-operation'));
        }
      });
    },
		loadData () {
			this.duplicateActiveMonitoringPlan();
		},
    async getSurveys () {
      const headers = {
        patientId: this.detailedUser.userData.id,
        filterCase: 'ALL',
        limitForOccurrences: 50,
      };
      await this.$store.dispatch('mentalhealth/getSurveyReportForPatient', headers);
    },
    openAssessments (index) {
		},
		formatElapsedTime (startDate) {
			const startDateObject = new Date(startDate);
			const currentDateObject = new Date();

			const timeDifference = currentDateObject - startDateObject;
			const secondsDifference = Math.floor(timeDifference / 1000);
			const minutesDifference = Math.floor(secondsDifference / 60);
			const hoursDifference = Math.floor(minutesDifference / 60);
			const daysDifference = Math.floor(hoursDifference / 24);

			if (daysDifference >= 7) {
				const weeksDifference = Math.floor(daysDifference / 7);
				return `${weeksDifference} ${weeksDifference === 1 ? 'week' : 'weeks'} old`;
			} else if (daysDifference >= 1) {
				return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} old`;
			} else if (hoursDifference >= 1) {
				return 'less than a day old';
			} else if (minutesDifference >= 1) {
				return 'less than an hour old';
			} else {
				return 'a few seconds old';
			}
		},
		lastAssessmentTime (survey) {
			// var recurrence = survey.surveyRecurrencesList[0];
			if (survey.startDate) {
				const startDate = new Date(survey.startDate);
				const currentDate = new Date();

				const timeDifference = currentDate - startDate;
				const secondsDifference = Math.floor(timeDifference / 1000);
				const minutesDifference = Math.floor(secondsDifference / 60);
				const hoursDifference = Math.floor(minutesDifference / 60);
				const daysDifference = Math.floor(hoursDifference / 24);
				const monthsDifference = Math.floor(daysDifference / 30);

				if (monthsDifference >= 1) {
					return `${monthsDifference} ${monthsDifference === 1 ? this.$t('month') : this.$t('months')} ago`;
				} else if (daysDifference >= 7) {
					const weeksDifference = Math.floor(daysDifference / 7);
					return `${weeksDifference} ${weeksDifference === 1 ? this.$t('week') : this.$t('weeks')} ago`;
				} else if (daysDifference >= 1) {
					return `${daysDifference} ${daysDifference === 1 ? this.$t('day') : this.$t('days')} ago`;
				} else if (hoursDifference >= 1) {
					return `${hoursDifference} ${hoursDifference === 1 ? this.$t('hour') : this.$t('hours')} ago`;
				} else if (minutesDifference >= 1) {
					return `${minutesDifference} ${minutesDifference === 1 ? this.$t('minute') : this.$t('minutes')} ago`;
				} else {
					return 'a few seconds ago';
				}
			}
			return '';
		},
		duplicateActiveMonitoringPlan () {
			const id = this.patientActiveTreatmentPlan.id;

			this.$store.dispatch('treatmentplan/duplicateActiveMonitoringPlan', id)
				.then((message) => {
					this.$store.commit('alerts/add', {
						type: 'success',
						message: message,
					});
					setTimeout(() => {
						this.$store.commit('alerts/clear', {
							type: 'success',
							message: 'message',
						});
					}, 3000); // 3000 milliseconds = 3 seconds
					this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
				});
		},
		getLocalizedMeasurementPeriod (period) {
			if (period === 'M') {
				return this.$t('monthly');
			} else if (period === 'W') {
				return this.$t('weekly');
			} else if (period === 'D') {
				return this.$t('daily');
			} else {
				return '';
			}
		},
		getTimeWithAMPM (time) {
			const [hours, minutes] = time.split(':');
			const formattedTime = `${hours}:${minutes} ${this.getAMPM(hours)}`;
			return formattedTime;
		},
		getAMPM (hours) {
			return hours >= 12 ? 'PM' : 'AM';
		},
		sinhronizeDrawers () {
			const parameters = {
				detailsDrawer: true,
				page: this.$route.name.toLowerCase(),
			};
			this.$store.commit('SET_DETAILS_DRAWER', parameters);
		},
		sinhronizeDrawer () {
			this.$store.commit('SET_PATIENT_DETAILS_DRAWER', 'monitoringPlansEdit');
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
				updateDrawer: true,
				page2: 'patientdetails',
			});
		},
		async saveTimers (val) {
			await this.$store.dispatch('treatmentplan/updateDeviceTimes', val).then(async () => {
				await this.initialize();
			});
		},
		async assignToTreatmentPlan (val) {
			var body = {
				treatmentPlanId: val.treatmentPlanId,
				deviceType: val.deviceType,
				measurementPeriod: 'D',
				measurementsCount: 1,
				time1: '08:00',
			};
			await this.$store.dispatch('treatmentplan/assignDevice', body).then(() => {
				this.initialize();
			});
		},
		async deAssignToTreatmentPlan (val) {
			await this.$store.dispatch('treatmentplan/deassignDevice', val.id).then(() => {
				this.initialize();
			});
		},
		deleteMedicalDevice: async function (val) {
			var body = {
				id: val.id,
				patientId: this.detailedUser.userData.id,
			};
			try {
				await this.$store.dispatch('medicaldevice/deleteMedicalDevice', body);
				this.initialize();
			} catch (error) {
				if (error.response && error.response.status === 403) {
					this.$store.commit('alerts/add', {
						id: 'addedHospital',
						type: 'error',
						color: 'main_red',
						message: this.$t('failed'),
					});
				} else {
					this.$store.commit('alerts/add', {
						id: 'addedHospital',
						type: 'error',
						color: 'main_red',
						message: this.$t('failed'),
					});
				}
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
		async saveParameters (val) {
			var body = {
				deviceDefaultValuesJson: JSON.stringify(val.deviceDefaultValuesJson),
				patientId: this.detailedUser.userData.id,
				deviceIdentifier: val.deviceIdentifier,
			};
			await this.$store.dispatch('medicaldevice/updateDeviceDefaultValuesJsonForMedicalDevice', body);
		},
		stringFormater (val) {
			return this.$t(val);
		},
		async initialize () {
			this.assignDevices = [];
			this.monitoringDevice = [];
			this.filteredDevices = [];
			await this.$store.dispatch('medicaldevice/getmedicaldevicesforpatientV2', this.detailedUser.userData.id).then(res => {
				res.data.medicalDevicesList[0].assignedMedicalDevicesList.forEach(item => {
					var manufacturerName = this.verifiersFromRepo.find(element => element.lowValue === item.manufacturerId).shortDescription;
					var tableElement = {
						treatmentPlanId: res.data?.patientActiveTreatmentPlan?.id ?? 0,
						id: item.id,
						deviceDefaultValuesJsonNames: Object.keys(JSON.parse(item.deviceDefaultValuesJson)),
						deviceDefaultValuesJson: JSON.parse(item.deviceDefaultValuesJson),
						name: item.deviceName,
						nameEsp: item.deviceNameEsp,
						deviceType: item.deviceType,
						manufacturer: manufacturerName,
						manufacturerId: item.manufacturerId,
						isManualMeasurement: item.isManualMeasurement,
						deviceIdentifier: item.deviceIdentifier,
						isThisDeviceAssignedToMonitoringPlan: item.isThisDeviceAssignedToMonitoringPlan,
					};
					this.assignDevices.push(tableElement);
				});
				this.trustedHubIdentifier = res.data.medicalDevicesList[0].hubIdentifier;
				if (res.data.patientActiveTreatmentPlan) {
					// we commit the treatment plan to the store, and use it locally as treatmentPlansFromRepo. This is done to avoid the need to call the API again.
					this.$store.commit('treatmentplan/SET_TREATMENT_PLAN_FOR_PATIENT', [res.data.patientActiveTreatmentPlan]);

					res.data.patientActiveTreatmentPlan.treatmentPlanDevicesList.forEach(item => {
						var monitoringElement = {
							id: item.id,
							deviceType: item.deviceType,
							measurementPeriod: item.measurementPeriod,
							measurementsCount: item.measurementsCount,
							treatmentPlanId: item.treatmentPlanId,
							deviceTypeTitle: item.deviceTypeTitle,
							deviceTypeTitleEsp: item.deviceTypeTitleEsp,
							time1: item.time1 ? item.time1 : '',
							time2: item.time2 ? item.time2 : '',
							time3: item.time3 ? item.time3 : '',
							time4: item.time4 ? item.time4 : '',
						};
						this.monitoringDevice.push(monitoringElement);
					});
				}
			});
			var filteredPossibleDevices = [];
			await this.$store.dispatch('medicaldevice/getPossibleDevices').then(res => {
				if (res !== undefined) {
					res.forEach(item => {
						var flag = false;
						this.assignDevices.forEach(element => {
							if (element.deviceType === item.deviceType && element.manufacturerId === item.manufacturerId) {
								flag = true;
								return false;
							}
						});
						if (!flag) {
							filteredPossibleDevices.push(item);
						}
					});
					filteredPossibleDevices.forEach(item => {
						var	itemsSelect = {
							body: {
								deviceType: item.deviceType,
								manufacturerId: item.manufacturerId,
								manufacturerName: item.manufacturerName,
								hubIdentifier: this.trustedHubIdentifier,
								deviceName: item.deviceName,
							},
							deviceName: item.deviceName + ' (' + item.manufacturerName + ')',
						};
						this.filteredDevices.push(itemsSelect);
					});
				}
			});
		},
		async isManual (val) {
			var body = {
				patientId: this.detailedUser.userData.id,
				val: val,
			};
			await this.$store.dispatch('medicaldevice/updateIsManualMeasurementForMedicalDevice', body).then(async () => {
				this.initialize();
			});
		},
		async addDevice () {
			this.createDropdownStatus = true;
		},
	},
};
</script>
<style>
.transitionClass:hover{
  transform:translateY(-5px);
  transition:0.5s;
  background-color:#e7e7e7;
  cursor:pointer;
}
.survey-container {
  overflow-y: auto; /* Add vertical scroll */
  max-height: 400px; /* Set a maximum height for the container */
  margin-bottom: 20px; /* Add some bottom margin to separate from other elements */
}
</style>
